<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <validation-observer
      :ref="`${form.id}-form`"
      v-slot="{ invalid }"
      v-if="canEdit"
    >
      <b-row align-v="end">
        <b-col cols="12">
          <div class="form-group">
            <validation-provider
              name="intercorrencias"
              :rules="{ required: true }"
            >
              <VueEditor
                :id="intercorrencias"
                class="vue_edition"
                v-model="fields.intercorrencias"
                :editorToolbar="customToolbar"
              />
            </validation-provider>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-col cols="2">
          <b-button
            variant="primary"
            block
            class="h-38 h-56"
            :disabled="invalid"
            @click="saveItem"
          >
          {{ editingIndex !== null ? 'Atualizar' : 'Adicionar' }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>

    <b-row>
      <b-col>
        <table class="table-eyecare">
          <thead>
            <tr>
              <th>Intercorrências</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) of form.value" :key="index">              
              <span
                v-html="value.intercorrencias"
                class="vue_edition"
              />
              <td class="text-right">
                <div class="more-with-menu">
                  <MoreVertical class="more-icon" />
                  <div class="menu">
                    <b-button
                      :disabled="!canEdit"
                      variant="link"
                      @click="editItem(value, index)"
                    >
                      Editar
                    </b-button>
                    <b-button
                      variant="link remove mb-0"
                      :disabled="!canEdit"
                      @click="removeItem(index)"
                    >
                      Remover
                    </b-button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import { VueEditor } from "vue2-editor"

export default {
  components: {
    FormWrapper,
    MoreVertical,
    VueEditor,
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.intercorrencias,
    })
  },
  data() {
    return {
      intercorrencias: null,
      editingIndex: null,
      fields: this.getDefaultFields(),
      customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/intercorrencias', ['handleFields', 'handleProps']),
    getDefaultFields() {
      return {
        intercorrencias: null,
      }
    },
    saveItem() {
      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }

      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields = this.getDefaultFields()
      this.editingIndex = null
    },
    editItem(value, index) {
      this.fields = { ...value }
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach((key,index) => {
        this.removeItem(index)
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.button {
  height: 38px;
  margin-bottom: 24px;
}
.vue_edition {
  word-break: break-all;
  background-color: white;

  :deep(.ql-editor) {
      min-height: 100px !important;
    }
}
</style>
